export const menuItems = [
  { id: 1, name: "Supplier Onboarding", iconName: "home", url: "/supplier-onboarding" },
  {
    id: 2,
    name: "Onboarding",
    iconName: "onboarding",
    url: "/onboarding",
    subMenu: [
      {
        name: "New Request",
        url: "/onboarding",
      },
      {
        name: "Requested",
        url: "/onboarding/requested",
      },
    ],
  },

  {
    id: "M3",
    name: "Vehicles",
    iconName: "vehicles",
    url: "/add-vehicle",
    subMenu: [
      {
        name: "Overview",
        url: "/abc",
      },
      {
        name: "New Request",
        url: "/add-vehicle",
      },
    ],
  },

  {
    id: "M4",
    name: "Drivers",
    iconName: "drivers",
    url: "/drivers",
    subMenu: [
      {
        name: "Overview",
        url: "/abc",
      },
      {
        name: "New Request",
        url: "/abc",
      },
    ],
  },

  {
    id: "M5",
    name: "Clients",
    iconName: "clients",
    url: "/clients",
    subMenu: [
      {
        name: "Overview",
        url: "/abc",
      },
      {
        name: "New Request",
        url: "/abc",
      },
    ],
  },

  {
    id: "M6",
    name: "Finance",
    iconName: "finance",
    url: "/abc",
    // subMenu: [
    //   {
    //     name: "Vehicle Checks",
    //     url: "/maintenance/vehicle-checks",
    //   },
    //   {
    //     name: "Defect's",
    //     url: "/maintenance/defects",
    //   },
    //   {
    //     name: "Breakdown's",
    //     url: "/maintenance/breakdown",
    //   },
    // ],
  },

  {
    id: "M7",
    name: "Maintenance",
    iconName: "maintenance",
    url: "/maintenance/vehicle-checks",
    subMenu: [
      {
        name: "Vehicle Checks",
        url: "/maintenance/vehicle-checks",
      },
      {
        name: "Defect's",
        url: "/maintenance/defects",
      },
      {
        name: "Breakdown's",
        url: "/maintenance/breakdown",
      },
    ],
  },

  {
    id: "M8",
    name: "Assets",
    iconName: "assets",
    url: "/assets",
    subMenu: [
      {
        name: "Overview",
        url: "/abc",
      },
      {
        name: "New Request",
        url: "/abc",
      },
    ],
  },
];
