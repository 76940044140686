import styled from "styled-components";

export const StyledAside = styled.aside`
  width: 260px;
  background: #052b4a;
  min-height: 100vh;
  /* height: 100%; */
  transition: all ease 0.5s;

  .LogoPart {
    padding: 10px 15px;
    border-bottom: 1px solid #123a69;
    a {
      svg {
      }
    }
  }
  .navigation {
    padding: 28px 0px 0px 0;

    nav {
      ul {
        padding-left: 15px;

        li {
          .arrow {
            display: none;
          }
          padding: 5px 10px 5px 5px;
          margin-bottom: 15px;
          position: relative;
          cursor: pointer;

          a {
            color: #7899cd;
            font-size: 15px;
            font-weight: 600;
            .flex {
              .iconWrapper {
                width: 36px;
                height: 36px;
                background: #1c4164;
                border-radius: 100px;
                img {
                }
              }
              span {
              }
            }
          }
          &.active {
            .arrow {
              display: block;
              right: 0;

              svg {
                position: absolute;
              }
              svg.left {
                position: absolute;
                right: 0;
                top: -23px;
              }
              svg.right {
                position: absolute;
                right: 0;
                top: 51px;
              }
              &:after {
                content: "";
                width: 10px;
                height: 47px;
                background: #eaedf7;
                display: block;
                position: absolute;
                right: 0;
                top: 5px;
              }
            }
            .arrowIcon {
              display: none;
            }
            > a {
              background: #eaedf7;
              border-radius: 100px 0 0 100px;
              display: block;
              padding: 5px 10px 5px 5px;
              color: #164781;
            }

            .flex .iconWrapper {
              background: #1483de;
            }
          }

          ul {
            opacity: 0;
            height: 0;
            transition: all ease 0.5s;
            padding-left: 16px;
            padding-top: 17px;
            li {
              padding: 7px 10px 8px 0;
              margin-bottom: 0;
              a {
                color: #8ba9d7;
                font-size: 14px;
                font-weight: 400;
                padding-left: 24px;
                display: inline-block;
                position: relative;
                &:after,
                &:before {
                  background: #7899cd;
                  position: absolute;
                  content: "";
                  display: block;
                }
                &:after {
                  width: 10px;
                  height: 1px;
                  left: 6px;
                  top: 6px;
                }
                &:before {
                  width: 6px;
                  height: 6px;
                  border-radius: 100px;
                  left: 0;
                  top: 3px;
                }
                &.active {
                  color: #fff;
                }
              }
              &:after {
                content: "";
                width: 2px;
                height: 32px;
                background: #7899cd;
                position: absolute;
                left: 2px;
                bottom: 15px;
                opacity: 0.5;
              }
            }
          }
          .arrowIcon {
            position: absolute;
            right: 22px;
            top: 24px;
            transform: translateY(-50%) rotate(180deg);
          }
          &.active {
            .arrowIcon {
              transform: translateY(-50%) rotate(0deg);
            }
            ul {
              opacity: 1;
              height: auto;
            }
          }
        }
      }
    }
  }
`;
