import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import styled from "styled-components";
import Header from "./header/Header";
import Aside from "./Aside/Aside";
import { Onboarding } from "@bjsdistribution/tms-onboarding";
import { SupplierOnboarding } from "@bjsdistribution/tms-supplier-onboarding";
import { AddVehicle } from "@bjsdistribution/tms-add-vehicle";
import { VehicleChecks, Defect, Breakdown } from "@bjsdistribution/tms-vehicle-maintenance";
import Error from "./Error/Error";
import { useToggleMenu } from "../common/store";
import Login from "../Login";

function AppRouter() {
  const [toogleStatus, setToggleStatus] = useToggleMenu();
  const LoadingMessage = () => <div>Loading...</div>;
  return (
    <Router>
      <Login />
      <MainContainer className={toogleStatus ? "activeMenu" : ""}>
        <Aside />
        <div className="dashboard">
          <Header />
          <div className="placeHolder">
            <Suspense fallback={<LoadingMessage />}>
              <Routes>
                <Route path="/" element={<Navigate to="/onboarding" />} />
                <Route path="/supplier-onboarding/*" element={<SupplierOnboarding />} />
                <Route path="/add-vehicle*" element={<AddVehicle />} />
                <Route path="/onboarding/*" element={<Onboarding />} />
                <Route path="/maintenance/vehicle-checks" element={<VehicleChecks />} />
                <Route path="/maintenance/defects" element={<Defect />} />
                <Route path="/maintenance/breakdown" element={<Breakdown />} />
                <Route path="*" element={<Error />} />
                {/* Add more routes as needed */}
              </Routes>
            </Suspense>
          </div>
        </div>
      </MainContainer>
    </Router>
  );
}

const MainContainer = styled.div`
  display: flex;

  .dashboard {
    flex: 1;
    background: #eaedf7;
    transition: all ease 0.5s;
    .placeHolder {
      padding: 25px 20px 10px 20px;
    }
  }
`;

export default AppRouter;
